import React from "react"
import ReactDOM from "react-dom"
import {
  Advertorial,
  ReadMoreButton,
  IrisRelatedVideos,
  ImageGallery
} from "./components"
import { Screen, EventRegister } from "./utilities"
import { PropertyRenovationCalculator } from "@rea-group/renovation-calculator"
import { ConstructKit } from "@rea-group/construct-kit-core"
import { StyleSheetManager } from "styled-components"

class App {
  constructor(
    domUtils,
    perfToggleUtils,
    advertorialFactory,
    adviceGuidesSearch,
    tealiumService,
    telemetryService,
    tracker,
    document,
    window
  ) {
    this.domUtils = domUtils
    this.perfToggleUtils = perfToggleUtils
    this.advertorialFactory = advertorialFactory
    this.tealiumService = tealiumService
    this.telemetryService = telemetryService
    this.adviceGuidesSearch = adviceGuidesSearch
    this.tracker = tracker
    this.platform = Screen.pagePlatform()
    this.document = document
    this.window = window
    this.eventRegister = new EventRegister(
      this.telemetryService,
      this.tealiumService,
      window,
      domUtils
    )
  }

  renderAds(adsElement) {
    if (!adsElement || adsElement < 1) {
      return
    }

    Array.from(adsElement).forEach((adElement) => {
      this.reactRenderAdsToDom(adElement)
    })
  }

  renderStickyAds(stickyAdsElements) {
    const defaultAdGutter = 20
    const mobileMaxWidth = 639
    let adGutterIncrement = 0

    let viewportWidth = Math.max(
      this.document.documentElement.clientWidth,
      this.window.innerWidth || 0
    )
    let isSticky = viewportWidth > mobileMaxWidth
    let ruiHeader = this.document.getElementsByClassName("rui-header")[0]
    if (!this.domUtils.hasClassName(ruiHeader, "rui-header-not-fixed")) {
      adGutterIncrement += ruiHeader.getBoundingClientRect().height
    }

    // Specify sticky flag and offset from top value in pixels to implement sticky medrec ad on article page
    let stickyAdConfig = {
      sticky: isSticky,
      offsetTop: defaultAdGutter + adGutterIncrement
    }

    Array.from(stickyAdsElements).forEach((stickyAdsElement) => {
      this.reactRenderAdsToDom(stickyAdsElement, stickyAdConfig)
    })
  }

  reactRenderAdsToDom(adElement, stickyAdConfig = {}) {
    let advertorialModel = this.advertorialFactory.createModelFromElement(
      adElement,
      this.platform
    )

    ReactDOM.render(
      <Advertorial
        container={adElement}
        visibility={this.domUtils.getVisibilityValue(adElement)}
        display={this.domUtils.getDisplayValue(adElement)}
        config={advertorialModel.getConfig()}
        params={advertorialModel.getParams()}
        sticky={stickyAdConfig.sticky || false}
        offsetTop={stickyAdConfig.offsetTop || 0}
      />,
      adElement
    )
  }

  renderReadMoreButton(readMoreButtonNode) {
    if (readMoreButtonNode) {
      ReactDOM.render(<ReadMoreButton />, readMoreButtonNode)
    }
  }

  renderGalleries(galleryNodes, imageGalleryAdNode) {
    const advertorialModel = this.advertorialFactory.createModelFromElement(
      imageGalleryAdNode,
      this.platform
    )
    Array.from(galleryNodes).map((galleryNode) => {
      const galleryTitle = this.domUtils.getImageGalleryTitle(galleryNode)
      const images = this.domUtils.getImageGalleryImages(galleryNode)
      ReactDOM.render(
        <ImageGallery
          images={images}
          tealiumService={this.tealiumService}
          title={galleryTitle}
          advertorialModel={advertorialModel}
          domUtils={this.domUtils}
        />,
        galleryNode
      )
    })
  }

  renderIrisRelatedVideos(containerElement) {
    if (containerElement) {
      const { videoId, videoContainerId, irisGlobalId } =
        containerElement.dataset
      const videojs = this.window.videojs
      ReactDOM.render(
        <IrisRelatedVideos
          videojs={videojs}
          videoId={videoId}
          videoContainerId={videoContainerId}
          getIris={() => this.window[irisGlobalId]}
        />,
        containerElement
      )
    }
  }

  renderRenovationCalculator(nodes) {
    Array.from(nodes).forEach((node) => {
      const advertorialModel = this.advertorialFactory.createModelFromElement(
        node,
        this.platform
      )
      const params = { ...advertorialModel.getParams() }
      const config = { ...advertorialModel.getConfig() }
      const { getdataLayer, tealiumLoader } = this.tealiumService

      if (process.env.DEPLOY_ENV !== "production") {
        params.env = "reno_test"
      }

      // To avoid the global setup for font family by ConstructKit 
      const filterPlugin = (element) => {
        if (element.type === "decl" && element.props === "font") {
          element.value += "font-family: Museo-Sans-300, Helvetica Neue, Helvetica, Arial, sans-serif;"
        }
      }

      ReactDOM.render(
        <StyleSheetManager stylisPlugins={[filterPlugin]}>
          <ConstructKit>
            <PropertyRenovationCalculator
              dataLayer={getdataLayer()}
              tealiumLoader={() => tealiumLoader}
            />
          </ConstructKit>
        </StyleSheetManager>,
        node
      )
    })
  }

  beaconTrack() {
    if (!this.perfToggleUtils.isAnalyticsPerfToggleDisabled()) {
      this.tracker.initializeTracking()
    }
  }

  trackPageLoadWithTealium() {
    this.tealiumService.trackPageView()
  }

  registerEvent() {
    this.eventRegister.bindEvent()
  }

  initializeAdviceGuidesSearch(searchFormNode) {
    if (searchFormNode) {
      this.adviceGuidesSearch.initializeSearchForm(searchFormNode)
    }
  }
}

export default App
